<template>
  <div class="InspectAndConstruction">
    <SimpleCURD
      ref="InspectAndConstruction"
      :is-search="false"
      search-url="/api/inspect/list"
      add-or-update-url="/api/inspectAndConstruction/addOrUpdateProject"
      delete-url="/api/inspectAndConstruction/delete"
      :show-form="showForm"
      :model="addForm"
      :add-form-rules="addFormRules"
      is-pageable
      label-width="160px" operation-width="250px"
      :format-commit-data="formatCommitData"
      :show-add-button="showAddButton"
      :show-edit-button="showAddButton"
      :show-delete-button="showAddButton"
      :only-read="()=>!showAddButton()"
      @beforeAddOrUpdate="beforeAddOrUpdate"
      @add="add"
      @search="getProjectToDevice"
    >
      <template #searchOperation>
        <el-button type="primary" plain size="medium" @click="handleUploadFile('constructionDocument')">
          附件
        </el-button>
        <el-button v-show="showAddButton() && user().role.name !== '施工人员'" type="primary" plain size="medium" @click="inspectAndConstructionFinish">
          完工
        </el-button>
        <el-checkbox v-if="showAddButton" v-model="isSaveLocation">
          提交表单时保存点位
        </el-checkbox>
      </template>
      <template #operation="{row}">
        <el-button v-if="showAddButton()" type="primary" size="small" @click="handleUploadFile('img',row,'picture-card')">
          图片
        </el-button>
        <el-button v-if="!showAddButton()" type="primary" size="small" @click="handleImg(row)">
          图片
        </el-button>
        <el-button v-if="!showAddButton()" type="primary" size="small" @click="$refs.InspectAndConstruction.handleEdit(row)">
          查看
        </el-button>
      </template>
    </SimpleCURD>

    <SimpleUploadFileAllCommit ref="uploadFile"
                               multiple
                               :limit="limitFile"
                               :action="actionFile"
                               :remove-url="removeUrlFile"
                               :all-file-action="allFileAction"
                               accept="*/*"
                               @change="success"
                               @commit="success"
    >
      <template #before>
        <el-form-item label="项目名">
          <el-input v-model="getProjectName" disabled />
        </el-form-item>
      </template>
    </SimpleUploadFileAllCommit>
    <SimpleUploadFileAllCommit ref="uploadImg"
                               multiple
                               :remove-url="removeUrlImg"
                               :limit="limitImg"
                               :action="actionImg"
                               :all-file-action="allImgAction"
                               list-type="picture-card"
                               accept="image/jpeg,image/gif,image/png"
                               @change="$refs.InspectAndConstruction.search()"
                               @commit="$refs.InspectAndConstruction.search()"
    />
    <el-dialog
      title="查勘图片"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div v-for="(item,index) in fileSmallList" :key="index" style="display: inline-block;padding-right: 20px;">
        <el-image
          :src="item.url"
          class="image-slot"
          :preview-src-list="srcList"
          style="border: 1px solid #999999"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SimpleCURD from '@/components/simple/SimpleCURD'
import ArrayUtils from '@/common/js/ArrayUtils'
import util from '@/common/js/util'
import SimpleUploadFileAllCommit from '@/components/simple/SimpleUploadFileAllCommit'
import { Message } from 'element-ui'

export default {
  name: 'InspectAndConstruction',
  components: { SimpleUploadFileAllCommit, SimpleCURD },
  data() {
    const notshow = (type, data) => {
      const temp = ArrayUtils.getArrayObject(this.constructionDevice, 'id', data.constructionDeviceId)?.inspectDeviceId
      return !temp || temp !== 1
    }
    const checkSerialNo = (rule, value, callback) => {
      if (!value && ArrayUtils.getArrayObject(this.constructionDevice, 'id', this.addForm.constructionDeviceId)?.haveSerialNo) {
        callback(new Error('请输入设备序列号'))
      }
      callback()
    }
    const checkFireControlHost = (message, ...param) => {
      if (ArrayUtils.getArrayObject(this.constructionDevice, 'id', this.addForm.constructionDeviceId)?.inspectDeviceId === 1 && !param[1]) {
        param[2](new Error(message))
      }
      param[2]()
    }
    return {
      dialogVisible: false,
      fileSmallList: [],
      srcList: [],
      serialNoOptions: [],
      // inspect start
      addForm: {
        projectId: null,
        location: null,
        imagePath: null,
        serialNo: null,
        constructionDeviceId: null,
        infoTransmitLocation: null,
        brand: null,
        model: null,
        g4: null,
        nb: null,
        connectionMode: null,
        network: null,
        havePowerSupply: null,
        filePath: null,
        description: null,
        serialPort: null,
        inspectDeviceId: null,
        watchLocation: null
      },
      showForm: [
        {
          param: 'projectId',
          disabled: (type) => type !== 'search',
          name: '项目名称',
          search: true,
          type: 'select',
          hidden: true,
          filterable: true,
          option: () => this.user().projectList.filter(item => {
            return item.type.ordinal >= 1
          }).map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          }),
          formatter: (row) => ArrayUtils.getArrayObject(this.user().projectList, 'id', row.projectId)?.name,
          clearable: true,
        },
        {
          param: 'constructionDeviceId',
          name: '设备',
          disabled: (type) => type !== 'search' && !this.showAddButton(),
          search: true,
          type: 'select',
          clearable: true,
          blur: (type) => {
            if (this.addForm.constructionDeviceId) {
              this.$refs.InspectAndConstruction.$refs.simpleCURDAddForm.$refs.simpleForm.validateField('constructionDeviceId')
            }
            this.serialNoOptions = []
          },
          option: () => {
            return this.constructionDevice.filter(item => this.constructionDeviceName.includes(item.name) || !item.haveSerialNo).map(item => {
              return {
                label: item.name,
                value: item.id,
              }
            })
          },
          formatter: (row) => {
            const d = ArrayUtils.getArrayObject(this.constructionDevice, 'id', row.constructionDeviceId)
            return d ? <span>{d.name}</span> : <span>未定义</span>
          },
        },
        {
          param: 'serialNo',
          name: '设备序列号',
          type: 'slot',
          slotName: 'serialNo',
          search: true,
          content: (type, data) => {
            const remoteMethod = (query) => {
              if (query && query !== '') {
                if (!this.addForm.constructionDeviceId) {
                  this.$refs.InspectAndConstruction.$refs.simpleCURDAddForm.$refs.simpleForm.validateField('constructionDeviceId')
                  return
                }
                this.$get('/api/material/getAll', { serialNo: query, projectId: data.projectId, constructionId: this.addForm.constructionDeviceId, constructionDevice: ArrayUtils.getArrayObject(this.constructionDevice, 'id', this.addForm.constructionDeviceId)?.name }).then(result => {
                  this.serialNoOptions = result.data.map(item => {
                    return { label: item.serialNo, value: item.serialNo }
                  })
                })
              } else {
                this.serialNoOptions = []
              }
            }
            return <el-select
              style="width:100%"
              clearable
              disabled={type !== 'search' && !this.showAddButton()}
              vModel={this.addForm.serialNo}
              filterable
              remote
              reserveKeyword
              placeholder="请输入设备序列号"
              remoteMethod={remoteMethod}>
              {this.serialNoOptions.map(item => {
                return <el-option
                  label={item.label}
                  value={item.value}>
                </el-option>
              })}
            </el-select>
          },
          clearable: true,
          notShow: () => this.addForm.constructionDeviceId ? !ArrayUtils.getArrayObject(this.constructionDevice, 'id', this.addForm.constructionDeviceId)?.haveSerialNo : true
        },
        {
          param: 'serialNo',
          name: '设备序列号',
          search: true,
          clearable: true,
          hidden: true,
          notShow: (type) => type !== 'search'
        },
        {
          param: 'serialPort',
          name: '端口号',
          disabled: () => !this.showAddButton(),
          clearable: true,
          maxlength: '100',
        },
        {
          param: 'location',
          name: '点位信息',
          maxlength: '100',
          disabled: (type) => type !== 'search' && !this.showAddButton(),
          search: true,
          clearable: true,
        }, {
          param: 'watchLocation',
          name: '监控位置',
          maxlength: '100',
          disabled: (type) => type !== 'search' && !this.showAddButton(),
          search: true,
          clearable: true,
        },
        {
          param: 'havePowerSupply',
          name: '是否有电源',
          type: 'radio',
          width: '100px',
          disabled: () => !this.showAddButton(),
          clearable: true,
          radio: [{ label: '有', value: 0 }, { label: '无', value: 1 }],
          formatter: (row) => {
            switch (row.havePowerSupply) {
            case 0:return <el-tag type="success">有电源</el-tag>
            case 1:return <el-tag type="info">无电源</el-tag>
            default:return <el-tag type="info">未定义</el-tag>
            }
          },
        },
        { param: 'g4', name: '4G信号强度(移动)', disabled: () => !this.showAddButton(), type: 'select', hidden: true, option: [{ value: 3, label: '好' }, { value: 4, label: '中' }, { value: 5, label: '差' }, { value: 6, label: '无' }] },
        { param: 'nb', name: 'NB信号强度(移动)', disabled: () => !this.showAddButton(), type: 'select', hidden: true, option: [{ value: 3, label: '好' }, { value: 4, label: '中' }, { value: 5, label: '差' }, { value: 6, label: '无' }] },
        { param: 'brand', name: '主机品牌', clearable: true, disabled: () => !this.showAddButton(), hidden: true, maxlength: '100', notShow: notshow },
        { param: 'model', name: '主机型号', clearable: true, disabled: () => !this.showAddButton(), hidden: true, maxlength: '100', notShow: notshow },
        { param: 'connectionMode', name: '连接方式', disabled: () => !this.showAddButton(), clearable: true, hidden: true, maxlength: '100', notShow: notshow },
        {
          param: 'constructionCreateTime',
          name: '施工时间',
          width: '100px',
          disabled: () => !this.showAddButton(),
          type: 'date',
          date: 'date',
          notShow: true,
          formatter: (row) => {
            return util.formatDate.format(row.createTime, 'yyyy-MM-dd')
          }
        },
        { param: 'description', name: '备注', disabled: () => !this.showAddButton(), clearable: true, hidden: true, maxlength: '1000' },
      ],
      addFormRules: {
        projectId: [{ required: true, message: '请选择项目名称', trigger: 'blur' }],
        location: [{ required: true, message: '请输入点位信息', trigger: 'blur' }],
        constructionDeviceId: [{ required: true, message: '请输入设备', trigger: 'blur' }],
        brand: [{ validator: (...param) => checkFireControlHost('请输入消控主机的品牌信息', ...param) }],
        model: [{ validator: (...param) => checkFireControlHost('请输入消控主机的型号信息', ...param) }],
        connectionMode: [{ validator: (...param) => checkFireControlHost('请输入消控主机的连接方式', ...param) }],
        serialNo: [{ validator: checkSerialNo }],
      },
      // inspect end
      // file start
      showButton: true,
      limitFile: null,
      actionFile: '',
      removeUrlImg: '',
      limitImg: null,
      actionImg: '',
      allFileAction: '',
      allImgAction: '',
      // file end
      stages: [],
      removeUrlFile: '',
      constructionDevice: [],
      device: [],
      data: [],
      constructionDeviceName: [],
      isSaveLocation: true,
      location: null
    }
  },
  inject: ['user', 'reFlushSession', 'getStage', 'getDevice'],
  computed: {
    getProjectName() {
      return ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.InspectAndConstruction.searchAddForm.projectId)?.name
    },
  },
  mounted() {
    this.$watch(() => this.$refs.InspectAndConstruction.searchAddForm.projectId, () => {
      this.$refs.InspectAndConstruction.search()
      this.getDeviceName()
      this.showButton = this.changeShowButton()
    })
    this.user().projectList.filter(item => {
      return item.type.ordinal >= 1
    })
    // if (projectList && projectList.length > 0) {
    //   this.$set(this.$refs.InspectAndConstruction.searchAddForm, 'projectId', projectList[0].id)
    // }
  },
  created() {
    this.reFlushSession()
    this.getStage()
    this.getConstructionDevice()
  },
  methods: {
    getDeviceName() {
      this.$get('/api/material/getDeviceName?projectId=' + this.$refs.InspectAndConstruction.searchAddForm.projectId).then(result => {
        this.constructionDeviceName = result.data
      })
    },
    getConstructionDevice() {
      this.$get('/api/constructionDevice/getAll').then(result => {
        this.constructionDevice = result.data
      })
    },
    // inspect start
    formatCommitData(result) {
      result.forEach(item => {
        item.g4 = JSON.parse(item.network)[0]
        item.nb = JSON.parse(item.network)[1]
        item.status = item.status.ordinal
      })
      return result
    },
    getCurrentProject() {
      return ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.InspectAndConstruction?.searchAddForm.projectId)
    },
    showAddButton() {
      const ordinal = this.getCurrentProject()?.stage.ordinal
      return ordinal === 1
    },
    add(addForm) {
      addForm.projectId = this.$refs.InspectAndConstruction.searchAddForm.projectId
      addForm.stage = 1
      if (this.isSaveLocation) {
        addForm.location = this.location
      }
    },
    getProjectToDevice(data) {
      this.data = data
    },
    inspectAndConstructionFinish() {
      const table = this.$refs.InspectAndConstruction?.params
      if (table === null || table.length === 0) {
        Message({
          message: '项目的施工内容为空，修改完工失败！',
          type: 'error'
        })
        return
      }
      table.forEach(item => {
        if (item.constructionDeviceId === null || item.constructionDeviceId === 0) {
          Message({
            message: '请确保项目所有设备都有值！',
            type: 'error'
          })
        }
        if (item.serialNo === null || item.serialNo === 0) {
          Message({
            message: '请确保项目所有设备序列号都有值！',
            type: 'error'
          })
        }
      })
      const project = ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.InspectAndConstruction.searchAddForm.projectId)
      this.$confirm(project?.name + '项目确认完工吗？', '提示').then(() => {
        this.$get('/api/project/updateFinish', {
          projectId: this.$refs.InspectAndConstruction.searchAddForm.projectId,
          stage: 'FINISHED',
        }).then(() => {
          this.reFlushSession()
        })
      })
    },
    // inspect end
    // show dialog
    handleUploadFileProject(path) {
      this.$refs.uploadFile.visible()
      const project = this.getArrayObject()
      this.actionFile = `/api/project/upload/${path}?id=` + project.id
      this.$refs.InspectAndConstruction.search()
    },
    handleUploadFile(path, row, pic) {
      let fileList = []
      if (path === 'img') {
        if (row.constructionImagePath) {
          fileList = row.constructionImagePath.split(',').map((item, i) => {
            return {
              name: i,
              url: `/api/inspect/download?path=${encodeURI(item)}&projectId=${this.$refs.InspectAndConstruction.searchAddForm.projectId}&flag=construction`,
              fileName: encodeURI(item),
              index: i
            }
          })
        }
        this.$refs.uploadImg.fileList = fileList
        this.$refs.uploadImg.visible()
        this.removeUrlImg = `/api/inspect/delete/${row.id}/${path}?projectId=${this.$refs.InspectAndConstruction.searchAddForm.projectId}&type=construction`
        this.actionImg = `/api/inspect/upload/${path}?id=${row.id}&projectId=${this.$refs.InspectAndConstruction.searchAddForm.projectId}&type=construction`
        this.allImgAction = `/api/inspect/download/all?id=${row.id} &projectId=${this.$refs.InspectAndConstruction.searchAddForm.projectId}&type=construction`
      } else {
        this.reFlushSession().then(() => {
          const project = this.getArrayObject()
          if (project.constructionDocumentPath) {
            fileList = project.constructionDocumentPath.split(',').map((item, i) => {
              return {
                name: `${item}`,
                url: `/api/inspect/download?path=${encodeURI(item)}&isCompress=false&projectId=${this.$refs.InspectAndConstruction.searchAddForm.projectId}&type=${path}`,
                fileName: encodeURI(item),
                index: i
              }
            })
          }
          this.$refs.uploadFile.fileList = fileList
          this.removeUrlFile = `/api/project/delete/${path}/${project.id}`
          this.actionFile = `/api/project/upload/${path}?id=${project.id}`
          this.allFileAction = `/api/project/download/${path}/all?id=${project.id}`
          this.$refs.uploadFile.visible()
        })
      }
    },
    handleImg(row) {
      this.fileSmallList = []
      let imgList = []
      this.srcList = []
      if (row.constructionImagePath) {
        this.fileSmallList = row.constructionImagePath.split(',').map((item, i) => {
          return {
            name: i,
            url: `/api/inspect/download?path=${encodeURI(item)}&isCompress=true&projectId=${this.$refs.InspectAndConstruction.searchAddForm.projectId}&flag=construction`,
            fileName: encodeURI(item),
            index: i
          }
        })
        imgList = row.constructionImagePath.split(',').map((item, i) => {
          return {
            name: i,
            url: `/api/inspect/download?path=${encodeURI(item)}&isCompress=false&projectId=${this.$refs.InspectAndConstruction.searchAddForm.projectId}&flag=construction`,
            fileName: encodeURI(item),
            index: i
          }
        })
      }
      imgList.forEach((item) => {
        this.srcList.push(item.url)
      })
      this.dialogVisible = true
    },
    beforeAddOrUpdate() {
      this.location = this.addForm.location
    },
    // hidden dialog
    // file start
    success() {
      this.$refs.InspectAndConstruction.search()
      this.showButton = true
    },
    deleteFileProject(path) {
      this.$confirm('确认删除项目 ' + this.getArrayObject().name + '的附件吗？', '删除').then(() => {
        this.$post(`/api/project/delete/${path}/` + this.getArrayObject().id).then(() => {
          this.$refs.InspectAndConstruction.search()
          this.$forceUpdate()
          this.showButton = false
        })
      })
    },
    // file end
    deleteFile(row) {
      this.$post(`/api/inspect/delete/${row.id}/file`, { path: row.filePath }).then(() => {
        this.$refs.InspectAndConstruction.search()
      })
    },
    showProjectFileUrl() {
      return !this.getArrayObject() ? '#' : '/api/project/download/inspectDocument/' + this.getArrayObject().id
    },
    getArrayObject() {
      return !this.$refs.InspectAndConstruction ? '#' : ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.InspectAndConstruction.searchAddForm.projectId)
    },
    changeShowButton() {
      return this.getArrayObject() ? this.getArrayObject().inspectDocumentPath : false
    },
  },
}

</script>

<style scoped>
a {
  text-decoration: none;
  color: #409EFF;
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}

/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
  color: #FFF;
}

/* 正在点击的链接*/
a:active {
  text-decoration: none;
  color: #FFF;
}

.el-icon-delete {
  display: none;
}
.el-checkbox{
  padding-left: 10px;
}

.el-upload-list__item:hover .el-icon-delete {
  position: absolute;
  right: 5px;
  display: block;
}
.image-slot{
  width: 100px;
  height: 100px;
  background-color: #c9c9c9;
  vertical-align: middle;
}
</style>
